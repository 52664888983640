.leave-review-page-container {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
    align-items: center;
}

.leave-review-page-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.leave-review-form .form-group {
    margin-bottom: 15px;
}

.leave-review-form label {
    display: block;
    margin-bottom: 5px;
}

.leave-review-form input,
.leave-review-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.leave-review-form textarea {
    height: 100px;
}

.leave-review-form input:focus,
.leave-review-form textarea:focus {
    border: 1px solid #007BFF;
    outline: none;
}

.error {
    color: #FF0000;
    font-size: 12px;
}

.leave-review-submit-btn {
    width: 33%;
    padding: 10px;
    background-color: #044fa0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.leave-review-submit-btn:hover {
    background-color: #007BFF;
    font-weight: bold;
}


