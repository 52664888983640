.why-choose-us-section {
    background-color: #f5f5f5;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

 

.why-choose-us-container {
    background-color: #f5f5f5; 
    padding: 50px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px;
    text-align: center;
    max-width: 1000px; 
    margin: 0 auto; 
  }
  
  .reason {
    background-color: #ffffff; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(50% - 20px); /* Two columns */
    box-sizing: border-box;
  }
  
  
  .reason-icon {
    font-size: 40px;
    margin-bottom: 10px;
    color: #007BFF;
  }
  
  .reason-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .reason-description {
    font-size: 14px;
    color: #555555; 
  }

  .button-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .contact-us-btn {
    width: fit-content;
    padding: 10px 20px;
    border: 3px solid #ddd;
    background-color: white;
    color: #333;
    cursor: pointer;
    border-radius: 25px; 
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .contact-us-btn:hover {
    background-color: #f0f0f0;
    color: #044fa0; 
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .reason {
      width: calc(100% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .spacing-container {
      padding: 30px 0;
    }
  
    .why-choose-us-container {
      padding: 20px;
    }
  
    .contact-us-btn {
      padding: 8px 16px;
      font-size: 12px;
    }
  }

  @media (min-width: 321px) and (max-width: 479px) {

    .reason {
      height: auto;
    }

    .reason-icon {
      font-size: 25px;
    }
    
    .reason-heading {
      font-size: 15px;
    }
    
    .reason-description {
      font-size: 12px;
     }
  
    .contact-us-btn {
      margin-top: 0px;
      width: fit-content;
      height: 15px;
      font-size:10px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  }
  

  @media (max-width: 320px) {

    .reason {
      height: auto;
    }

    .reason-icon {
      font-size: 25px;
    }
    
    .reason-heading {
      font-size: 15px;
    }
    
    .reason-description {
      font-size: 12px;
     }
  
    .contact-us-btn {
      margin-top: 0px;
      width: fit-content;
      height: 15px;
      font-size:10px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  }