.leave-review-container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: white; 
  }
  
  .review-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px; 
    padding: 20px;
    margin-top: -48px;
  }
  

  .review-form .form-group {
    margin-bottom: 10px;
  }

  .review-form input,
  .review-form textarea,
  .review-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
  }
  
  .review-form input:focus,
  .review-form textarea:focus,
  .review-form select:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }

  .review-form textarea {
    height: 150px;
    resize: none;
  }
  
  .review-submit-btn {
    padding: 10px 20px;
    background-color: #044fa0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    width: fit-content; 
    align-self: center;
  }
  
  .review-submit-btn:hover {
    background-color: #007BFF;
    font-weight: bold;
  }
  
  .error {
    color: #e63946;
    font-size: 12px;
    margin-top: -8px;
    margin-bottom: 10px;
    margin-top: 5px;
  }