.tour-packages-section {
  margin-top: 0px;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
}

.tour-packages-container {
  margin-top: 0px;
  background: white;
  padding: 50px 20px;
  width: 80%; 
  margin: 0 auto;
}

.grid-container {
  display: grid;
  grid-template-columns:  repeat(3, 1fr); 
  gap: 20px;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.tour-package {
  width: 350px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out; 
  justify-content: center;
  align-items: center;
}

.tour-package:hover {
  transform: scale(1.05); 
}

.tour-package img.tour-image {
  width: 100%;
  height: 350px; 
  object-fit: cover;
  transition: transform 0.3s ease-in-out; 
}

.tour-package:hover img.tour-image {
  transform: scale(1.1); 
}

.tour-info {
  padding: 15px;
  text-align: center;
}

.tour-info .days {
  font-size: 14px;
  color: #a09e9e;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align:left;
}

.tour-info h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.tour-info p {
  margin: 5px 0;
  color: #555;
}

.price-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #ddd;
}

.price-and-button .price {
  font-size: 18px;
}

.price-and-button .price .from {
  font-weight: normal; 
  font-size:12px;
}

.price-and-button .price .amount {
  font-weight: bold; 
}

.price-and-button .book-now-btn1 {
  width: 30%;
  padding: 10px;
  background-color: #044fa0;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
}

.price-and-button .book-now-btn1:hover {
  background-color: #007BFF;
  font-weight: bold;
}

.more-info {
  width: 22%; 
  margin: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: white;
}

.more-info .description {
  font-style: italic;
  font-family: 'Georgia', serif; 
  color: #555;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.more-info .view-btn {
  padding: 10px 20px;
  margin: 10px 0;
  border: 3px solid #ddd;
  background-color: white;
  color: #044fa0;
  cursor: pointer;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none ;
}

.more-info .view-btn:hover {
  color: #007BFF;
  font-weight: bold;
  text-decoration: none;
}

.more-info .view-btn a{

  color: #044fa0;
  text-decoration: none ;
}

.more-info .view-btn a:hover {
  color: #007BFF;
  text-decoration: none;  
}


@media (max-width: 1119px) {

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  
}

@media (max-width: 768px){

  .grid-container {
    grid-template-columns: repeat(1, minmax(350px, 1fr));;
    justify-items: center;
    align-items: center;
    width: 100%;
  }


  .tour-package img.tour-image {
    height: 200px;
}

.tour-info .days {
  font-size: 12px;
}

.tour-info h3 {
  font-size: 14px;
}

.tour-info p {
  font-size: 12px;
}

.price-and-button .price {
  font-size: 15px;
}

.price-and-button .price .from {
  font-size:12px;
}

.price-and-button .book-now-btn1 {
  width: 30%;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  padding: 5 10px;
  display: flex;
 
}

.more-info .description {
  font-size: 14px;
}

.more-info .view-btn {
  font-size: 12px;
}
  

}

@media (max-width: 479px) {

  .grid-container {
    grid-template-columns: repeat(1, minmax(350px, 1fr));;
  }

  .tour-package, .more-info {
    width: 100%;
  }

  .tour-package img.tour-image {
    height: 200px;
}

.tour-info .days {
  font-size: 12px;
}

.tour-info h3 {
  font-size: 14px;
}

.tour-info p {
  font-size: 12px;
}

.price-and-button .price {
  font-size: 14px;
}

.price-and-button .price .from {
  font-size:10px;
}

.price-and-button .book-now-btn1 {
  width: 30%;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  padding: 5 10px;
  display: flex;
 
}

.more-info .description {
  font-size: 14px;
}

.more-info .view-btn {
  font-size: 12px;
}


}


@media (max-width: 385px) {

  .grid-container {
    grid-template-columns: repeat(1, minmax(250px, 1fr));;
  }

.tour-package img.tour-image {
      height: 200px;
  }

  .tour-info .days {
    font-size: 12px;
  }
  
  .tour-info h3 {
    font-size: 14px;
  }
  
  .tour-info p {
    font-size: 12px;
  }
  
  .price-and-button .price {
    font-size: 14px;
  }
  
  .price-and-button .price .from {
    font-size:10px;
  }
  
  .price-and-button .book-now-btn1 {
    width: 30%;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    padding: 5 10px;
    display: flex;
   
  }

  .more-info .description {
    font-size: 14px;
  }
  
  .more-info .view-btn {
    font-size: 12px;
  }

  
}