.faq-container {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #044fa0;
  }
  