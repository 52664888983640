.navbar-container {
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  
}

.top-navbar {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  font-size: 14px;
  color: #044fa0;
  background: rgba(245, 245, 245, 0.8);
  position: fixed; 
  top: 0;
  width: 100%;
  z-index: 1000; 
  box-sizing: border-box;
}

.top-navbar .logo {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.6); 
  padding: 5px 10px; 
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-decoration: none; 
  transition: background-color 0.3s ease, color 0.3s ease;
}

.top-navbar .logo:hover {
  background-color: rgba(255, 255, 255, 0.8); 
  cursor: pointer; 
}

.top-navbar .logo img {
  width: 50px; 
  height: auto;
  margin-right: 10px;
}
.top-navbar .logo-text {
  font-size: 18px; 
  margin-left: 10px;
  font-weight: bold;
  color: #044fa0; 
}

.contact-info {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-end; 
  text-align: right;
  width: 100%;
}

.contact-info .icon {
  margin-right: 5px;
  color: rgba(0, 123, 255, 0.7);  
}

.contact-info a, .contact-info span {
  color: #044fa0;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.contact-info a:hover, .contact-info span:hover {
  color: #007BFF;
}

.contact-info .contact-us-link, 
.contact-info .book-now-link, 
.contact-info .my-booking-link {
  color: #044fa0;
  text-decoration: none;
}

.contact-info .contact-us-link:hover, 
.contact-info .book-now-link:hover, 
.contact-info .my-booking-link:hover {
  color: #007BFF;
}


.nav-social-icons {
  display: flex;
}

.nav-social-icons span {
  color: #044fa0;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px; 
}

.nav-social-icons span:hover {
  color: #007BFF; 
}



.main-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background: transparent; 
  font-size: 20px;
  color: white; 
  z-index: 999;
  position:relative;
  top:60px;
  width: 100%; 
  box-sizing: border-box;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;
}

.nav-links a:hover {
  color: #007BFF;
}

.nav-links .active {
  color: #007BFF;
}


.top-navbar.scroll-hidden {
  display: none;
}


@media (min-width: 1200px) {
  
  .main-navbar {
    font-size: 22px;
  }

}



@media (min-width: 768px) and (max-width: 1023px) {
 
  .top-navbar {
    display: flex;
    overflow: hidden;
    height: 60px;
    padding: 5px 20px ;
    font-size: 14px;
  }
  
  .top-navbar .logo {
    padding: 5px 10px;
  }
  
  
  .top-navbar .logo img {
    width: 35px; 
    margin-right: 0px;
  }

  .top-navbar .logo-text {
    font-size: 15px; 
    margin-left: 3px;
  }
  
  .contact-info {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end; 
    text-align: right;
    padding: 5px 12px;
    width: 100%;
  }

  .contact-info span,
  .contact-info a {
    display: inline;
  }

  .contact-info .icon {
    margin-right: 2px;
  }
 

  .nav-social-icons span {
    font-size: 12px;
    margin-right: 2px;
  }
  
.main-navbar {
  font-size: 22px;
  top:40px;
}




 
  

}


@media (min-width: 601px) and (max-width: 767px) {

  .top-navbar {
    display: flex;
    overflow: hidden;
    height: 50px;
    padding: 5px 20px ;
    font-size: 12px;
  }
  
  .top-navbar .logo {
    padding: 5px 10px;
  }
  
  
  .top-navbar .logo img {
    width: 35px; 
    margin-right: 0px;
  }

  .top-navbar .logo-text {
    font-size: 14px; 
    margin-left: 3px;
  }
  
  .contact-info {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end; 
    text-align: right;
    padding: 5px 12px;
    width: 100%;
  }

  .contact-info span,
  .contact-info a {
    display: inline;
  }

  .contact-info .icon {
    margin-right: 2px;
  }
 

  .nav-social-icons span {
    font-size: 8px;
    margin-right: 2px;
  }
  
.main-navbar {
  font-size: 18px;
  top:40px;
}

.nav-links {
  gap: 16px;
}


}

@media (min-width: 480px) and (max-width: 600px){
  .top-navbar {
    overflow: hidden;
    height: 50px;
    padding: 5px 20px;
    font-size: 10px;
  }
  
  .top-navbar .logo {
    padding: 5px 10px;
  }
  
  
  .top-navbar .logo img {
    width: 20px; 
    margin-right: 0px;
  }

  .top-navbar .logo-text {
    font-size: 10px; 
    margin-left: 3px;
  }
  
  .contact-info {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end; 
    text-align: right;
    padding: 5px 10px;
    width: 100%;
  }

  .contact-info span,
  .contact-info a {
    display: inline;
  }

  .contact-info .icon {
    margin-right: 2px;
  }
 

  .nav-social-icons span {
    font-size: 8px;
    margin-right: 2px;
  }
  
.main-navbar {
  font-size: 16px;
  top:40px;
}

.nav-links {
  gap: 12px;
}

}


@media (min-width: 321px) and (max-width: 479px) {
  .top-navbar {
    overflow: hidden;
    height: 50px;
    padding: 5px 20px;
    font-size: 8px;
  }
  
  .top-navbar .logo {
    padding: 5px 10px;
  }
  
  
  .top-navbar .logo img {
    width: 20px; 
    margin-right: 0px;
  }

  .top-navbar .logo-text {
    font-size: 10px; 
    margin-left: 3px;
  }
  
  .contact-info {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end; 
    text-align: right;
    padding: 5px 5px;
    width: 100%;
  }

  .contact-info span,
  .contact-info a {
    display: inline;
  }

  .contact-info .icon {
    margin-right: 2px;
  }
 

  .nav-social-icons span {
    font-size: 8px;
    margin-right: 2px;
  }
  
.main-navbar {
  font-size: 10px;
  top:40px;
}

.nav-links {
  gap: 12px;
}

}


@media (max-width: 320px) {
  .top-navbar {
    overflow: hidden;
    height: 50px;
    padding: 5px 20px;
    font-size: 8px;
  }
  
  
  
  .top-navbar .logo img {
    width: 20px; 
    margin-right: 0px;
  }

  .top-navbar .logo-text {
    font-size: 10px; 
    margin-left: 3px;
  }
  
  .contact-info {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-end; 
    text-align: right;
    padding: 5px 5px;
    width: 100%;
  }

  .contact-info span,
  .contact-info a {
    display: inline;
  }

  .contact-info .icon {
    margin-right: 2px;
  }
 

  .nav-social-icons span {
    font-size: 8px;
    margin-right: 2px;
  }
  
.main-navbar {
  font-size: 10px;
  top:40px;
}

.nav-links {
  gap: 12px;
}

}

