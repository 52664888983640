.breadcrumb-nav {
    position: relative;
    padding: 1rem;
    margin-top: -150px; 
    z-index: 10;
    
  }

  .breadcrumb-nav.form-breadcrumb {
    margin-top: 50px; 
  }
  
  
  .breadcrumb-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    display: flex;
    align-items: center;
  }
  
  .breadcrumb-link {
    text-decoration: none;
    color: #007bff;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  
  .breadcrumb-link-active {
    font-weight: bold;
    color: #000;
  }
  
  .breadcrumb-icon {
    margin-right: 0.5rem;
  }
  
  .breadcrumb-separator {
    margin: 0 0.5rem;
    color: #6c757d;
  }
  
  .breadcrumb-current .breadcrumb-link {
    pointer-events: none;
    cursor: default;
  }



  @media (min-width: 1200px) {
    .breadcrumb-link {
      font-size: 1.25rem; 
    }
  
    .breadcrumb-icon {
      font-size: 1.5rem; 
    }
  
    .breadcrumb-separator {
      font-size: 1.25rem;
    }
  }
  
 
  @media (min-width: 768px) and (max-width: 1023px) {
    .breadcrumb-link {
      font-size: 1rem; 
    }
  
    .breadcrumb-icon {
      font-size: 1.25rem; 
    }
  
    .breadcrumb-separator {
      font-size: 1rem; 
    }
  }
  
 
  @media (min-width: 480px) and (max-width: 767px) {
    .breadcrumb-link {
      font-size: 0.875rem; 
    }
  
    .breadcrumb-icon {
      font-size: 1rem; 
    }
  
    .breadcrumb-separator {
      font-size: 0.875rem; 
    }
  }
  
 
  @media (min-width: 321px) and (max-width: 479px) {
    .breadcrumb-link {
      font-size: 0.75rem;
    }
  
    .breadcrumb-icon {
      font-size: 0.875rem; 
    }
  
    .breadcrumb-separator {
      font-size: 0.75rem; 
    }
  }
  
  
  @media (max-width: 320px) {
    .breadcrumb-link {
      font-size: 0.625rem; 
    }
  
    .breadcrumb-icon {
      font-size: 0.75rem; 
    }
  
    .breadcrumb-separator {
      font-size: 0.625rem; 
    }
  }
  