.what-other-clients-say-section {
  display: flex;
  flex-direction: column; 
  background-color: #f5f5f5;
  padding: 50px 20px; 
  overflow: visible;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  width: 100%;
}


.spacing-Container {
  flex: 1;
  padding: 20px 0 40px 0;
  text-align: left;
  font-family: 'Cursive', sans-serif;
  font-size: 24px;
  color: #333;
  position: relative;
}

.spacing-Container p {
  margin: 0;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
}

.spacing-Container .p2{
  margin-top: 10px;
  font-size: 14px;
}

.line-Wrapper {
  
  justify-content: center;
  width: fit-content; 
}

.elegant-Line {
  height: 2px;
  background-color: #044fa0;
  width: 33%;
  position: relative;
  left: 33%;
  right: 33%;
  top: 100%;
  margin-top: 5px; 
}

.swiper-container {
  flex: 2;
  width: 300px;
  padding-bottom: 50px; 
}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 0;
}

.review {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px; 
  margin: 0 10px; 
  overflow-y: auto;
}

.review:hover {
  transform: scale(1.05); 
}

.stars {
  color: #007BFF;
  margin-bottom: 10px;
}

.review-text {
  font-size: 14px;
  color: #555555; 
  margin-bottom: 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.reviewer-name {
  font-size: 16px;
  font-weight: bold;
  font-style: italic; 
  color: #333; 
  margin-top: 10px;
  display: block;
}

.swiper-pagination {
  position: absolute;
  margin-bottom: -5px; 
 
 
}



.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #044fa0;
}



.swiper-pagination-bullet-active {
  background-color: #044fa0; 
  opacity: 1; 
}


.swiper-button-prev,
.swiper-button-next {
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 10;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  font-weight:bold;
}

.button-wrapper {
  display: flex;
  justify-content: center; 
  align-items: center;
  margin-top: 20px;
  width: 100%; 
  height: auto; 
  max-height: fit-content; 
  padding-bottom: 20px; 
}


.other-reviews-btn {
 
  padding: 1rem;
  border: 3px solid #ddd;
  background-color: white;
  color: #333;
  cursor: pointer;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.other-reviews-btn:hover {
  background-color: #f0f0f0;
  color: #044fa0;
  font-weight: bold;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: calc(50% - 20px);
    margin: 0 5px;
  }

  .review {
    padding: 15px; 
    font-size: 14px; 
  
}
}

@media (max-width: 480px) {
  .swiper-slide {
    width: 100%;
    margin: 0 3px;
  }

  .review {
    padding: 10px; 
    font-size: 12px; 
  }

  .contact-us-btn {
    width: 80%;
  }

}

@media (min-width: 321px) and (max-width: 479px) {
  .spacing-Container p {
    font-size: 18px;
  }

  .line-Wrapper p {
    font-size: 16px;
  }

  .spacing-Container .p2 {
    margin-top: 5px;
    font-size: 12px;

  }
  
  .other-reviews-btn {
      font-size:10px;
      
    }

  

}

@media (max-width: 320px) {
  .spacing-Container p {
    font-size: 18px;
  }

  .line-Wrapper p {
    font-size: 16px;
  }

  .spacing-Container .p2 {
    margin-top: 5px;
    font-size: 12px;

  }

  .other-reviews-btn {
      font-size:10px;
      
  }


} 