.cover-photo-container {
    width: 100%;
    height: auto; 
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease; 
}

.cover-photo-container:hover {
  transform: scale(1.05); 
  z-index: 1; 
}
 
.cover-photo {
  width: 100%;
  height: auto;
  object-fit: cover; 
  position: relative;
  transition: transform 0.3s ease, z-index 0s; 
}

  .cover-photo-text {
    color: white;
    text-align: center;
    background-color: transparent; 
    padding: 20px;
    border-radius: 10px;
    max-width: 800px; 
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .cover-photo-text h1 {
    font-size: 24px; 
    margin: 0 0 10px;
  }
  
  .cover-photo-text p {
    font-size: 16px; 
    margin: 0;
  }
 
  @media (min-width: 1200px) {

    .cover-photo {
      width: 100%;
      object-fit: cover;
      max-height: 400px; 
    }
    .cover-photo-text {
      width: auto;
      padding: 20px;
    }
      
      .cover-photo-text h1 {
        font-size: 18px; 
      }
      
      .cover-photo-text p {
        font-size: 16px; 
      }
  }

  @media (max-width: 1199px) {
    .cover-photo {
      width: 100%;
      object-fit: cover;
    }

 
    .cover-photo-text {
      width: auto;
      padding: 20px;
    }
      
      .cover-photo-text h1 {
        font-size: 18px; 
      }
      
      .cover-photo-text p {
        font-size: 16px; 
      }
  }
 
 
  @media (max-width: 1023px) {

    .cover-photo {
      width: 100%;
      height: 300px; 
      object-fit: cover;
    }
    .cover-photo-text {
      width: auto;
      padding: 10px;
    }
      
      .cover-photo-text h1 {
        font-size: 16px; 
      }
      
      .cover-photo-text p {
        font-size: 14px; 
      }
  }

 
  @media (max-width: 767px) {

    .cover-photo {
      width: 100%;
      height: 200px; 
      object-fit: cover;
    }
    .cover-photo-text {
      width: auto;
      padding: 5px;
    }
      
      .cover-photo-text h1 {
        font-size: 14px; 
      }
      
      .cover-photo-text p {
        font-size: 12px; 
      }
  }
  
  
  @media (min-width: 410px) and (max-width: 479px) {

    .cover-photo {
      width: 100%;
      height: 150px; 
    }
    .cover-photo-text {
      width: auto;
      padding: 5px;
    }
      
      .cover-photo-text h1 {
        font-size: 10px; 
      }
      
      .cover-photo-text p {
        font-size: 10px; 
      }
  }
  
  @media (max-width: 409px) {
   
  
  .cover-photo {
    width: 100%;
    height: 150px; 
  }
  .cover-photo-text {
    width: auto;
    padding: 5px;
  }
    
    .cover-photo-text h1 {
      font-size: 8px; 
    }
    
    .cover-photo-text p {
      font-size: 8px; 
    }
  }