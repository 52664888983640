
.hero-container {
  position: relative;
  width: 100%;
  height: 90vh !important;
  overflow: visible;
  
}

.navbar-container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.slide {
  min-width: 100%;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.slide.active {
  opacity: 1;
}

.slide:not(.active)

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-box{
 
  display: flex;
  flex-direction: row;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  
}

.form-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;  
  left: 0; 
  right: 0; 
  padding: 1rem;
  border-radius: 8px;
  gap: 1rem; /* Space between form groups */
}

.form-fields .form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height:fit-content;
  padding: 1rem;
}

.form-field {
  margin-left: 0.5rem; /* Space between label and input */
}


button.prev, button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: none;
  border: none; 
}

button.prev {
  left: 10px;
}

button.next {
  right: 10px;
}


button.prev:focus, button.next:focus {
  outline: none;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.hero-title {
  font-size: 48px;
  margin: 0;
  animation: slideInFromLeft 2s ease-in-out forwards;
}

.hero-description {
  font-size: 24px;
  margin: 10px 0;
  animation: slideInFromRight 2s ease-in-out forwards;
}

.hero-content .hero-book-now-btn {
  width: 150px;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #044fa0;
  color: white;
  background: #044fa0;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hero-content .hero-book-now-btn:hover {
  background-color: #007BFF;
}



.form-fields .quote-submit-btn  {
  width: 33%;
  padding: 10px;
  background-color: #007BFF;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 1px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  transition: background-color 0.3s ease, font-weight 0.3s ease;
}

.quote-submit-btn:hover {
  background-color: #044fa0;
  font-weight: bold;
}

.single-image {
  position: relative;
  width: 100%;
  height: auto;
}

.single-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (min-width: 1200px){

  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    
  }

}


@media (min-width: 1024px) and (max-width: 1199px){

  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }

  .hero-title {
    font-size: 35px;
  }
  
  .hero-description {
    font-size: 22px;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    
  }

  
}


@media (min-width: 768px) and (max-width: 1023px) {

  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }

  .hero-title {
    font-size: 25px;
  }
  
  .hero-description {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    
  }


}



@media (min-width: 480px) and (max-width: 767px) {

  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }

  .hero-title {
    font-size: 22px;
  }
  
  .hero-description {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    
  }

  .form-fields .quote-submit-btn  {
    width: 50%;
    font-size: 10px;
  
  }


}  




@media (min-width: 321px) and (max-width: 479px) {

  .hero-container {
    width: 100%;
    height: 100%;
     box-sizing: border-box;
     margin-right: 0px;

  }
  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }

  .hero-title {
    font-size: 14px;
  }
  
  .hero-description {
    font-size: 10px;
    margin: 8px 0;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    width: fit-content;
    height: 15px;
    font-size:8px;
    align-items: center;
    justify-content: center;
    padding: 5 10px;
    display: flex;
   
  }

  .form-fields .quote-submit-btn  {
    font-size: 12px;

  }

  .prev, .next {

    font-size: 15px;
  }

  .form-box{
 
    display: flex;
    flex-wrap: wrap;
    
  }

  .form-fields {
    padding: 0.5rem;
    border-radius: 4px;
    gap: 0.5rem;
  }
  
  .form-fields .form-group {
  
    padding: 0.5rem;
  } 
  
  .form-field {
    margin-left: 0rem; 
  }

  .labels{
    font-size: 10px;
  }
  
}

@media (max-width: 320px) {
  .hero-container {
    width: 100%;
    height: 100%;

  }
  .hero-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

  }

  .hero-title {
    font-size: 14px;
  }
  
  .hero-description {
    font-size: 10px;
    margin: 8px 0;
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-book-now-btn {
    margin-top: 0px;
    width: fit-content;
    height: 15px;
    font-size:8px;
    align-items: center;
    justify-content: center;
    padding: 5 10px;
    display: flex;
   
  }

  .prev, .next {

    font-size: 15px;
  }

  .form-box{
 
    display: flex;
    flex-wrap: wrap;
    
  }

  .form-fields {
    padding: 0.5rem;
    border-radius: 4px;
    gap: 0.5rem;
  }
  
  .form-fields .form-group {
  
    padding: 0.5rem;
  } 
  
  .form-field {
    margin-left: 0rem; 
  }

  .labels{
    font-size: 10px;
  }
  
}
  


