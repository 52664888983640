.my-booking-container {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  align-items: center;
  }
  
  .my-booking-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group textarea {
    height: 100px;
  }
  
  .form-group .error {
    color: red;
    font-size: 12px;
  }
  
    .form-group input:focus,
    .form-group textarea:focus {
    border: 1px solid #007BFF;
    outline: none;
 }

  .my-booking-btn {
    width: 33%;
    padding: 10px;
    background-color: #044fa0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  
  .my-booking-btn:hover {
    background-color: #007BFF;
  }
  