.contact-us-leave-review-container {
  margin-top: 0px;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

}

.react-tabs {
  max-width: 600px;
  margin: 0 auto;
  border:none;

}

.react-tabs__tab-list {
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  border-bottom: none;
  
}

.react-tabs__tab {
  list-style: none;
  padding: 10px 20px;
  background: none; 
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  font-weight: normal; 
  outline: none;
}

.react-tabs__tab--selected {
  border: none;
  font-weight: bold;
  
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover {
  color: #007BFF; 
  transition: width 0.3s ease-in-out;
}


.contact-us-container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: white;
    margin:-55px auto;
  
}



.spacing-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  text-align: center;
  font-family: 'Cursive', sans-serif;
  font-size: 24px;
  color: #333;
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.section-header p {
  margin: 0 auto;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
  width: fit-content;
}

.contact-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.contact-form .form-group {
    margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
}

.contact-form input:focus,
.contact-form textarea:focus,
.review-form input:focus,
.review-form textarea:focus,
.review-form select:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
    outline: none;
}


.contact-form textarea,
.review-form textarea {
  height: 150px;
  resize: none;
}

.contact-component-btn {
    width: 33%;
    background-color: #044fa0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    display: flex;
}

.contact-component-btn:hover {
  background-color: #007BFF;
  font-weight: bold;
}

.error {
  color: #e63946;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .contact-us-leave-review-container {
    flex-direction: column;
  }

  .contact-us-container, .leave-review-section {
    max-width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 479px) {
  .react-tabs__tab-list {
    font-size: 14px;
    
  }
  
  .contact-form .form-group {
    margin-bottom: 5px;
  }
  
  .contact-component-btn {
      font-size: 10px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
  }
  
  
  }


@media (max-width: 320px) {
.react-tabs__tab-list {
  font-size: 14px;
  
}

.contact-form .form-group {
  margin-bottom: 5px;
}

.contact-component-btn {
    font-size: 10px;
    align-items: center;
    justify-content: center;
    padding: 5 10px;
    display: flex;
}


}