.admin-dashboard {
    margin-top: 40px;
    min-height: 500px;
    padding: 20px;
  }
  
  .admin-dashboard h1 {
    margin-bottom: 20px;
  }
  
 
  .admin-list {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .admin-list-item h3 {
    margin: 0;
  }
  
  .delete-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .delete-confirmation button {
    margin: 0 10px;
  }
  
  .MuiGrid-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .MuiGrid-item {
    padding: 10px;
    box-sizing: border-box;
  }
  
  .MuiCard-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .MuiCard-root:hover {
    transform: translateY(-5px);
  }
  
  .MuiCardContent-root {
    flex-grow: 1;
  }
  
  .MuiCardActions-root {
    display: flex;
    justify-content: flex-end;
  }
  
  /* General Button Styling */
  .MuiButton-root {
    text-transform: none;
  }
  
  .MuiButton-containedPrimary {
    background-color: #007BFF;
    color: white;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #0056b3;
  }

  .error {
    color: red;
    font-size: 0.875em;
    margin-top: 5px;
  }