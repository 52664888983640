.top-destinations-section {
    margin-top: 0px;
    padding: 50px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
  }
  
  
  .destinations-container {
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    grid-gap: 10px;
    max-width: 1200px; 
    margin: 0 auto; 
  }
  
  .destination {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .destination-0 {
    grid-column: span 2;
    grid-row: 1 / 2;
  }
  
  .destination-1 {
    grid-column: span 1;
    grid-row: 1 / 2;
  }
  
  .destination-2,
  .destination-3,
  .destination-4 {
    grid-column: span 1;
    grid-row: 2 / 3;
  }
  
  .destination-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    justify-content: center;
    transition: transform 0.3s ease, z-index 0s;
  }

  .destination:hover .destination-image {
    transform: scale(1.05);
    z-index: 1; 
  }
  
  .destination-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    text-decoration: none;
    transition: color 0.3s ease;
    z-index: 2;
  }

  .destination-name:hover {
    color: #007BFF; 
    cursor: pointer;
    font-weight: bold;
  }
  
  .button-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .more-destinations-btn {
    width: fit-content;
    padding: 10px 20px;
    border: 3px solid #ddd;
    background-color: white;
    color: #333;
    cursor: pointer;
    border-radius: 25px; 
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .more-destinations-btn a {
    text-decoration: none;
  }
  
  
  .more-destinations-btn:hover {
    background-color: #f0f0f0;
    color: #044fa0; 
    font-weight: bold;
  }

  @media (min-width: 1200px) {
    .destination-name {
      font-size: 24px;
      
    }
  
  
  
    .more-destinations-btn {
      margin-top: 0px;
      width: fit-content;
      font-size:16px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  
  
  }
 
 
 
 
 
  @media (max-width: 1199px) {
    .destination-name {
      font-size: 22px;
      
    }
  
  
  
    .more-destinations-btn {
      margin-top: 0px;
      width: fit-content;
      font-size:16px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  
  
  }
 
 
 
  @media (max-width: 1023px) {
    .destination-name {
      font-size: 18px;
      
    }
  
  
  
    .more-destinations-btn {
      margin-top: 0px;
      width: fit-content;
      font-size:16px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  
  
  }
 
 
 
 
  @media (max-width: 767px) {
    .destination-name {
      font-size: 14px;
      
    }
  
  
  
    .more-destinations-btn {
      margin-top: 0px;
      width: fit-content;
      font-size:14px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  
  
  }
  

  @media (min-width: 321px) and (max-width: 479px) {

    .destination-name {
      font-size: 10px;
      
    }
  
    .destination-2,
    .destination-3,
    .destination-4 {
      height: 90px; 
    }
  
    .more-destinations-btn {
      margin-top: 0px;
      width: fit-content;
      height: 15px;
      font-size:10px;
      align-items: center;
      justify-content: center;
      padding: 5 10px;
      display: flex;
     
    }
  
  
  }
  

  @media (max-width: 320px) {
  .destination-name {
    font-size: 10px;
    
  }

  .destination-2,
  .destination-3,
  .destination-4 {
    height: 90px; 
  }

  .more-destinations-btn {
    margin-top: 0px;
    width: fit-content;
    height: 15px;
    font-size:10px;
    align-items: center;
    justify-content: center;
    padding: 5 10px;
    display: flex;
   
  }


}