.tour-package-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  top: 70%; 
  position: relative;
  margin-bottom: 120px;
  z-index: 10; 
}


.tour-package-detail {
  background: white;
  padding: 20px;
  width: 800px; 
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; 
}
.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.tab-buttons button.active {
  background-color: #044fa0;
  color: white;
}

.tab-buttons button:hover {
  background-color: #007BFF;
  color: white;
}

.tab-content {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tab-content h2 {
  margin-top: 0;
  font-size: 18px;
}

.it-icons {
font-size: 12px;
color: #044fa0; 

}

/* Common styles for all lists */
.itinerary-list {
  list-style-type: none; 
  padding-left: 0; 
}

.itinerary-list li {
  margin: 8px 0; 
  display: flex;
  align-items: center; 
}

.list-icons { 
  margin-right: 8px; 
  font-size: 10px; 
}



@media (min-width: 768px) and (max-width: 1023px) {

  .tour-package-detail {
    width: 700px;
  }

  .tab-buttons button {
    font-size: 14px;
    padding: 12px 20px;
  }

}


@media (min-width: 480px) and (max-width: 767px){

  .tour-package-detail {
    padding: 15px;
    width: 90%;
  }

  .tab-buttons button {
    font-size: 10px;
    padding: 10px 15px;
  }

  .tab-content {
    padding: 20px;
  }

  .tour-package-detail h2 {

    font-size: 18px;
  }

  .tab-content h2 {
    font-size: 16px;
  }

  .tab-content p {
    font-size: 14px;
  }



}


@media (min-width: 381px) and (max-width: 479px) {

  .tour-package-detail {
    padding: 10px;
    width: 90%;
  }

  .tab-buttons button {
    font-size: 10px;
    padding: 8px 10px;
  }

  .tab-content {
    padding: 15px;
  }

  .tour-package-detail h2 {

    font-size: 16px;
  }

  .tab-content h2 {
    font-size: 14px;
  }

  .tab-content p {
    font-size: 12px;
  }



}


@media (max-width: 380px) {

  .tour-package-detail {
    padding: 10px;
    width: 90%;
  }

  .tab-buttons button {
    font-size: 08px;
    padding: 6px 8px;
  }

  .tab-content {
    padding: 10px;
  }

  .tour-package-detail h2 {

    font-size: 16px;
  }

  .tab-content h2 {
    font-size: 14px;
  }

  .tab-content p {
    font-size: 12px;
  }

}
