
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  background: transparent; /* Ensure the body has a transparent background */
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background: transparent; /* Ensure the root div has a transparent background */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacing-container {
  padding: 20px 0 40px 0;
  text-align: center;
  font-family: 'Cursive', sans-serif; 
  font-size: 24px;
  color: #333;
  position: relative;
}

.spacing-container p {
  margin: 0;
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
}

.line-wrapper {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.elegant-line {
  height: 2px;
  background-color: #044fa0;
  width: 33%; 
  position: absolute;
  left: 33%;
  right: 33%; 
  top: 100%;
  margin-top: 5px; 
}

.back-button {
  background: none;
  border: none;
  color: #044fa0;
  cursor: pointer;
  font-size: 1.5rem;
  margin-bottom: 20px;
}


@media (min-width: 321px) and (max-width: 479px) {
  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    
  }


  .spacing-container p {
    font-size: 18px;
  }
  
  
  
  }

@media (max-width:320px){

  body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
  }
.spacing-container p {
  font-size: 18px;
}



}