.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}


.places-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.places-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.place-item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.place-item.left {
  flex-direction: row;
}

.place-item.right {
  flex-direction: row-reverse;
}

.place-item.left .place-image,
.place-item.right .place-image,
.place-item.left .place-description,
.place-item.right .place-description {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.place-item.left .place-image.slide-in-left {
  opacity: 1;
  transform: translateX(0);
}

.place-item.right .place-image.slide-in-right {
  opacity: 1;
  transform: translateX(0);
}

.place-item.left .place-description.slide-in-right {
  opacity: 1;
  transform: translateX(0);
}

.place-item.right .place-description.slide-in-left {
  opacity: 1;
  transform: translateX(0);
}

.place-item.left .place-image {
  transform: translateX(-100%);
}

.place-item.right .place-image {
  transform: translateX(100%);
}

.place-item.left .place-description {
  transform: translateX(100%);
}

.place-item.right .place-description {
  transform: translateX(-100%);
}

.place-image {
  flex: 1;
  position: relative;
  overflow: hidden;
  width: 550px;
  height: 750px; 
}

.place-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(4, 79, 160, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 18px;
}

.place-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.place-tittle-link {
  text-decoration: none;
  color:#333;
  transition: color 0.3s ease;
}

.place-tittle-link:hover {
  cursor: pointer;
}

.place-link:hover {
  font-weight: bold;
  color: black; 
  cursor: pointer;
}

.place-description {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.place-description p {
  margin: 0;
  margin-bottom: 150px;
  color: #333;
}

.place-title {
  margin: 0;
  margin-bottom: 400px; 
  margin-left: 80px;
  font-size: 24px;
  color: #333;
}


.button-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.goto-tourpackages-btn {
  text-decoration: none;
  padding: 10px 20px;
  border: 3px solid #ddd;
  background-color: white;
  color: #333;
  cursor: pointer;
  border-radius: 25px; 
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.goto-tourpackages-btn:hover {
  background-color: #f0f0f0;
  color: #044fa0; 
  font-weight: bold;
}


@media (min-width: 769px) {


  
}

@media (max-width: 1020px) {
  .place-item {
    flex-direction: column;
  }
  
  .place-item.left,
  .place-item.right {
    flex-direction: column;
  }

  .place-image {
    width: 100%; 
    height: auto; 
  }

  .place-name {
    font-size: 14px;
  }

  .place-description p {
    margin-top: 10px;
    margin-bottom: 50px;
    
  }
  
  .place-title {
    margin-bottom: 0px; 
    margin: 0 auto;
   
  }
}