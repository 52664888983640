.footer-section {
    background-color: #f8f8f8;
    padding: 40px 20px;
    position: relative;
  }
  
  .footer-top {
    height: 2px;
    background-color: #ddd;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
    padding-bottom: 100px;
    width: 50%; 
    margin-left: auto; 
    margin-right: auto; 
  }
  
  .footer-div {
    flex: 1;
    margin: 0 20px;
  }
  
  .footer-div h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .footer-div p, .footer-div ul, .footer-div li {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #555;
    font-size: 14px;
  }
  
  .footer-div a {
    font-size:14px;
    text-decoration: none;
    color: black;
  }
  
  .footer-div a:hover {
    text-decoration: none;
    color:#007BFF;
  }
  
  .book-now-btn {
    padding: 5px 10px;
    background-color: #044fa0;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
  }
  
  .book-now-btn:hover {
    background-color: #007BFF;
    font-weight: bold;
  }

  .footer-content .icon {
    color: #007BFF; 
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 100px; 
    margin-bottom: 30px;
  }
  
  .footer-social-icons svg {
    color: #044fa0;
    font-size: 24px;
    cursor: pointer;
  }
  
  .footer-line {
    height: 2px;
    background-color: #ddd;
    margin: 20px auto;
    width: 50%;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px;
    font-size: 14px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  .payment-methods img {
    margin: 0 5px;
    width: 24px;  
    height: 24px; 
  }
  
  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    font-size: 24px;
    color: #044fa0;
    cursor: pointer;
    z-index: 1000;
  }
  
  .back-to-top.visible {
    display: block;
  }
  
  

  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-div {
      margin-bottom: 20px;
    }
  
    .footer-social-icons {
      margin-top: 40px;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .payment-methods img {
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .footer-content {
      width: 100%;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: center;
    }
  
    .back-to-top svg {
      font-size: 20px;
    }
  }