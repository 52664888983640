.tour-packages-page-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 50px 20px;
  width: 100%;
  margin: 0 auto ;
  background: white;
  position: relative;
}

.tour-packages-page-link {
  text-decoration: none;
  color: inherit;
}

.tour-packages-page-item {
  width: 80%;
  margin: 20px; 
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tour-packages-page-item:hover {
  transform: scale(1.05);
}

.tour-packages-page-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.tour-packages-page-item:hover .tour-packages-page-img {
  transform: scale(1.1);
}

.tour-packages-page-info {
  height: 165px;
  padding: 20px;
  text-align: center;
}


.tour-packages-page-info h3 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.tour-packages-page-info p {
  margin: 5px 0;
  color: #555;
}

.tour-packages-page-price-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #ddd;
}

.tour-packages-page-price-button .price {
  font-size: 18px;
}

.tour-packages-page-price-button .from {
  font-weight: normal;
  font-size: 12px;
}

.tour-packages-page-price-button .amount {
  font-weight: bold;
}

.tour-packages-page-price-button .details {
  font-size: 12px;
  color: #007BFF;
}


.tour-packages-page-price-button .book-now-btn {
  padding: 10px 20px;
  background-color: #044fa0;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.tour-packages-page-price-button .book-now-btn:hover {
  background-color: #007BFF;
  font-weight: bold;
}

.tour-packages-page-more-info-container {
  grid-column: span 3; 
  display: flex;
  justify-content: center;
}

.tour-packages-page-more-info {
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: white;
}

.tour-packages-page-more-info .description {
  font-style: italic;
  font-family: 'Georgia', serif;
  color: #555;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.tour-packages-page-more-info .button-container {
  display: flex;
  gap: 20px; 
}

.tour-packages-page-more-info .button-container .view-btn,
.tour-packages-page-more-info .button-container .contact-btn {
  padding: 10px 20px;
  margin: 10px 0;
  border: 3px solid #ddd;
  background-color: white;
  color: #333;
  cursor: pointer;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.tour-packages-page-more-info .button-container .view-btn:hover,
.tour-packages-page-more-info .button-container .contact-btn:hover {
  background-color: #f0f0f0;
  color: #044fa0;
  font-weight: bold;
}

@media (min-width: 1200px) {


  .tour-packages-page-info {
    height: 210px;
    padding: 10px;
  }

}


@media (max-width: 1199px) {
  .tour-packages-page-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .tour-packages-page-more-info-container {
    grid-column: span 2; 
  }
}

@media (min-width: 768px) and (max-width: 1199px) {

  .tour-packages-page-container {
    padding: 30px 10px;
    width: 100%;
  }

  .tour-packages-page-info {
    height: 200px;
    padding: 10px;
  }
  .tour-packages-page-info h3 {
    margin: 5px 0;
    font-size: 18px;
  }
  
  .tour-packages-page-info p {
    font-size: 16px;
  }
  
  .tour-packages-page-price-button {
    padding: 5px;
  }

  .tour-packages-page-price-button .details {
    margin-right: 5px;
  }

  .tour-packages-page-price-button .book-now-btn {
    font-size: 18px;
    
  }

}

@media (max-width: 767px) {
  .tour-packages-page-container {
    grid-template-columns: 1fr;
  }

  .tour-packages-page-more-info-container {
    grid-column: span 1;
  }

  .tour-packages-page-more-info .button-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 601px) and (max-width: 767px) {

 .tour-packages-page-container {
    padding: 30px 10px;
    width: 100%;
  }

  .tour-packages-page-info {
    height: 150px;
    padding: 10px;
  }



}

@media (min-width: 480px) and (max-width: 600px) {

 .tour-packages-page-container {
    padding: 30px 10px;
    width: 100%;
  }

  .tour-packages-page-info {
    height: 150px;
    padding: 10px;
  }
  
  .tour-packages-page-info h3 {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .tour-packages-page-info p {
    font-size: 14px;
  }
  
  .tour-packages-page-price-button {
    padding: 5px;
  }

  .tour-packages-page-price-button .book-now-btn {
    padding: 10px 15px;
    font-size: 14px;
  }

  .tour-packages-page-price-button .price {
    font-size: 14px;
  }

  .tour-packages-page-price-button .details {
    font-size: 10px;
  }

}





@media (min-width: 321px) and (max-width: 479px) {

  .tour-packages-page-container {
    padding: 30px 10px;
    width: 100%;
    margin: 0 auto;
  }

  .tour-packages-page-img {
    width: 100%;
    height: 260px;
    object-fit: cover;
  }

  .tour-packages-page-info {
    height: 180px;
    padding: 10px;
  }
  
  .tour-packages-page-info h3 {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .tour-packages-page-info p {
    font-size: 14px;
  }
  
  .tour-packages-page-price-button {
    padding: 10px;
  }

  .tour-packages-page-price-button .book-now-btn {
    padding: 10px 15px;
    font-size: 14px;
  }

  .tour-packages-page-price-button .price {
    font-size: 16px;
  }

  .tour-packages-page-price-button .details {
    font-size: 10px;
  }

}

@media (max-width: 320px){

  .tour-packages-page-container {
    padding: 30px 10px;
    width: 100%;
  }

 

  .tour-packages-page-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .tour-packages-page-info {
    
    height: 200px;
    padding: 10px;
  }
  
  .tour-packages-page-info h3 {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .tour-packages-page-info p {
    font-size: 14px;
  }
  
  .tour-packages-page-price-button {
    padding: 15px;
  }



  .tour-packages-page-price-button .book-now-btn {
    padding: 5px 10px;
    font-size: 12px;
    
  }

  .tour-packages-page-price-button .price {
    font-size: 16px;
  }

  .tour-packages-page-price-button .details {
    font-size: 10px;
  }

}